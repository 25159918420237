import { Theme, styled } from '@easy-expense/ui-theme';
import { OpenSans } from '@easy-expense/ui-web-core';
import { useField } from 'formik';
import React from 'react';

export const ErrorText = styled(OpenSans.Primary)`
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes['2xs-10']}px;
  color: ${({ theme }: { theme: Theme }) => theme.colors.destructive};
  margin: 0px;
`;

export const FieldError: React.FC<React.PropsWithChildren<{ name: string }>> = (props) => {
  const { name } = props;
  const [, meta] = useField(name);
  const { touched, error } = meta;
  return (
    <ErrorText>{touched && error ? (Array.isArray(error) ? error[0] : error) : ' '}</ErrorText>
  );
};
