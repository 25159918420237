import { Suggestion } from '@easy-expense/data-schema-v2';
import { IconName } from '@easy-expense/ui-shared-components';
import React from 'react';

import {
  BaseSuggestionDropdown,
  SuggestionDropdownParams,
} from './BaseSuggestionDropdown.component';

export const InsertSuggestionDropdown: React.FC<
  React.PropsWithChildren<{ params: SuggestionDropdownParams<Suggestion> }>
> = ({ params }) => {
  const { suggestionArray, selectedKey } = params;

  const [suggestions, setSuggestions] = React.useState<Suggestion[]>(suggestionArray);
  const [suggestionName, setSuggestionName] = React.useState(
    suggestionArray.find((v) => v.key === selectedKey)?.value?.name ?? '',
  );

  function onInputFocus() {}
  function onInputBlur() {}

  return (
    <BaseSuggestionDropdown
      params={{
        ...params,
        suggestionName,
        setSuggestionName,
        suggestions,
        setSuggestions,
        onInputBlur,
        onInputFocus,
        allowCreateNew: true,
      }}
    />
  );
};
