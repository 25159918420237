import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, Spacer, OpenSans } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

export const LabelTextField: React.FC<
  React.PropsWithChildren<{
    label: string;
    active?: boolean;
    error?: boolean;
    errorMessage?: string;
    styles?: DoNotFixMeIAmAny;
  }>
> = (props) => {
  const { label, children, active, error, errorMessage, styles } = props;

  let color = 'secondary';
  if (active) {
    color = 'brandPrimary';
  }
  if (error) {
    color = 'destructive';
  }

  return (
    <Layout.Column>
      <OpenSans.Custom size="xs-12" color={color} weight="bold-700">
        {label}
      </OpenSans.Custom>
      <Spacer.Vertical size={6} />
      <Layout.Column>
        <Layout.Column
          border={error && !active ? [2, 'solid', 'destructive'] : [1, 'solid', 'inputBorder']}
          radius={4}
          px
          py={4}
          style={{
            minHeight: 40,
            borderBottomWidth: active ? 3 : undefined,
            borderBottomColor: active ? theme.colors.brandPrimary : undefined,
            ...styles,
          }}
          bg="inputBackground"
          justify
        >
          {children}
        </Layout.Column>
      </Layout.Column>
      {error ? (
        <Layout.Row align px>
          <Icon name="warning" color="red" size={16} />
          <OpenSans.Custom size="xs-12" weight="bold-700" color="destructive">
            {getTranslation('This field is required')}
          </OpenSans.Custom>
        </Layout.Row>
      ) : null}
      {errorMessage ? (
        <Layout.Row align px>
          <Icon name="warning" color="red" size={16} />
          <OpenSans.Custom size="xs-12" weight="bold-700" color="destructive">
            {errorMessage}
          </OpenSans.Custom>
        </Layout.Row>
      ) : null}
    </Layout.Column>
  );
};
