import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import { darken, lighten } from 'polished';
import React from 'react';

export const Badge: React.FC<{
  color: string;
  text: string;
}> = ({ color, text }) => {
  return (
    <Layout.Row
      style={{ backgroundColor: lighten(color.startsWith('hsl') ? 0.35 : 0.45, color) }}
      px={8}
      py={4}
      radius={4}
    >
      <OpenSans.Primary size="s-12" style={{ overflow: 'hidden', color: darken(0.35, color) }}>
        {text}
      </OpenSans.Primary>
    </Layout.Row>
  );
};
