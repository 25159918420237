import { currentUser } from '@easy-expense/auth-client';
import { ReportWithData } from '@easy-expense/data-schema-v2';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { dateFormatSkeleton, hueToColor } from '@easy-expense/utils-shared';
import _ from 'lodash';
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReportDownloadColumnCell } from './ReportDownloadColumnCell.component';
import { useReportsRows } from './useReportTableRows';
import { Badge } from '../Shared/Badge.component';

export const ReportMaterialTable: React.FC<React.PropsWithChildren<object>> = ({}) => {
  const navigate = useNavigate();
  const reportRows = useReportsRows();
  const { formatCurrency, formatDate } = useIntlStore();

  function onRowClick(row: ReportWithData) {
    navigate(`/report/${row.key}`);
  }

  const columns = React.useMemo<MRT_ColumnDef<ReportWithData>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.name,
        header: getTranslation('Report Name'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Report Name')}</OpenSans.Secondary>
        ),
        size: 300,
        Cell: ({ row }) => {
          const name = row.original.name;
          const color = row.original.color;
          return (
            <Layout.Row style={{ width: 300, height: 30 }} align>
              <Layout.Column
                style={{ width: 8, backgroundColor: hueToColor(color), height: '100%' }}
                radius={4}
              />
              <Spacer.Horizontal size={12} />
              <OpenSans.Primary size={'s-12'} weight="bold-700">
                {name}
              </OpenSans.Primary>
            </Layout.Row>
          );
        },
      },
      {
        accessorFn: (row) => row.clientValue?.name,
        accessorKey: 'clientValue.value.name',
        header: getTranslation('Client'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Client')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-12"
                style={{
                  width: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {row.original.clientValue?.name}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        accessorFn: (row) => row.total,
        accessorKey: 'clientValue.total',
        header: getTranslation('Total'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Total')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-12"
                style={{
                  width: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {formatCurrency(row.original.total)}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        accessorFn: (row) => row.expenses.length + row.incomes.length,
        header: getTranslation('Transactions'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Transactions')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          const transactions = row.original.expenses.length + row.original.incomes.length;
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-12"
                style={{
                  width: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {transactions}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        accessorFn: (row) => row.trips.length,
        accessorKey: 'trips',
        header: getTranslation('Trips'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Trips')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-12"
                style={{
                  width: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {row.original.trips.length}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        accessorFn: (row) => new Date(row.updatedAt),
        id: 'updatedAt',
        filterVariant: 'date-range',
        filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        header: getTranslation('Updated On'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Update On')}</OpenSans.Secondary>
        ),
        maxSize: 10,
        Cell: ({ row }) => {
          const date = row.original.updatedAt;
          return (
            <Layout.Column style={{ width: 60 }}>
              <OpenSans.Secondary size={'s-12'}>
                {formatDate(new Date(date), {
                  skeleton: dateFormatSkeleton.abbreviatedNoYear,
                })}
              </OpenSans.Secondary>
            </Layout.Column>
          );
        },
      },
      {
        accessorFn: (row) => row.status,
        name: 'status',
        id: 'status',
        header: getTranslation('Status'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Status')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          const status = row.original.status;
          const statusBadge = { color: '', text: '' };
          if (status === 'Unsent') {
            statusBadge.color = theme.colors.success;
            statusBadge.text = 'Active';
          } else if (status === 'Sent') {
            statusBadge.color = theme.colors.brandPrimary;
            statusBadge.text = 'Sent';
          } else if (status === 'Complete') {
            statusBadge.color = theme.colors.primary;
            statusBadge.text = 'Complete';
          }

          return (
            <Layout.Row>
              <Badge color={statusBadge.color} text={statusBadge.text} />
            </Layout.Row>
          );
        },
      },
      {
        accessorFn: (row) => row.key,
        name: 'download',
        header: getTranslation('Download'),
        filter: false,
        enableSorting: false,
        enableColumnFilter: false,
        size: 80,
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Download')}</OpenSans.Secondary>
        ),
        muiTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <Layout.Column align="flex-end">
              <ReportDownloadColumnCell row={row} />
            </Layout.Column>
          );
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: reportRows,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: [
        { desc: true, id: 'status' },
        { desc: true, id: 'updatedAt' },
      ],
      pagination: { pageIndex: 0, pageSize: 25 },
    },
    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
      FilterAltIcon: () => <Icon name="funnel" size={18} color={theme.colors.secondary} />,
      ViewColumnIcon: () => <Icon name="build-outline" size={18} color={theme.colors.secondary} />,
    },
    enableColumnActions: false,
    columnFilterDisplayMode: 'subheader',
    getRowId: (row) => row.key,
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.original),
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              borderColor: theme.colors.inputBorder,
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                borderColor: theme.colors.success,
                width: 500,
                paddingRight: 1,
              },
            }}
          />
          <Spacer.Horizontal size={6} />
          <MRT_ShowHideColumnsButton table={table} />
          <Spacer.Horizontal size={6} />

          <MRT_ToggleFiltersButton table={table} />
        </Layout.Row>
      );
    },
  });

  return (
    <Layout.Column>
      <MaterialReactTable table={table} />
    </Layout.Column>
  );
};
