import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const CurrencyField: React.FC<
  React.PropsWithChildren<{
    initialValue?: number;
    error?: boolean;
    onChange: (value: number) => void;
    label: string;
  }>
> = ({ initialValue, error, onChange, label }) => {
  const [value, setValue] = React.useState<string>(initialValue?.toString() ?? '');
  const [isFocused, setIsFocused] = React.useState(false);

  function numberWithCommas(x: number | string) {
    const t = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return t === 'NaN' ? '0' : t;
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.target;
    const cleanedInput = input.value
      .replace(/[^0-9.]/g, '') // Remove non-digits except for the decimal point
      .replace(/\.(?=.*\.)/g, '') // Remove all but the first decimal point
      .replace(/(\.\d{2})\d+/g, '$1') // Keep only two digits after the first decimal point
      .substring(0, 11);

    onChange(parseFloat(cleanedInput));
    setValue(numberWithCommas(cleanedInput));
  }

  return (
    <LabelTextField label={label} active={isFocused} error={error}>
      <Layout.Row style={{ width: '100%' }} align>
        <OpenSans.Primary style={{ marginRight: 6 }} weight="light-300">
          $
        </OpenSans.Primary>
        <OpenSans.Input
          value={value}
          type="text"
          weight="light-300"
          placeholder="0.00"
          style={{ flexGrow: 1, outline: 'none', width: '100%' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onInputChange(event)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </Layout.Row>
    </LabelTextField>
  );
};
