import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

/*
 * We need to reload the Iframe since there is a bug with iframe where the pdf is not loaded.
 * and the onload function does not call.
 * come relevant threads:
 * https://github.com/facebook/react/issues/6541
 * https://stackoverflow.com/questions/20572734/load-event-not-firing-when-iframe-is-loaded-in-chrome
 */

export const IFrameReloader: React.FC<{ url: string | null }> = ({ url }) => {
  const ref: React.LegacyRef<HTMLIFrameElement> = React.useRef(null);
  const intervalIdRef = React.useRef<NodeJS.Timeout>();
  const hasLoaded = React.useRef(false);

  React.useEffect(() => {
    if (ref?.current && url) {
      ref.current.src = `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
        url,
      )}`;
      ref.current;
    }
  }, [url]);

  React.useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      if (!hasLoaded.current && ref.current?.src) {
        ref.current.src += '';
      }

      if (hasLoaded.current) {
        clearInterval(intervalIdRef.current);
      }
    }, 3000);

    return () => clearInterval(intervalIdRef.current); // Cleanup interval on component unmount
  }, []);

  return (
    <Layout.Column style={{ height: '100%', backgroundColor: '#CFD6E0' }} justify>
      <Layout.Column style={{ height: '100%', width: '100%' }} center>
        <iframe
          ref={ref}
          style={{ height: '800px', maxWidth: '56rem', width: '500px' }}
          onLoad={() => {
            hasLoaded.current = true;
          }}
        ></iframe>
      </Layout.Column>
    </Layout.Column>
  );
};
