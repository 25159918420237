import {
  addWorkspaceMember,
  canModifyUsers,
  useCurrentWorkspace,
  useCachedMembers,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { validateFormSchema } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, Separator } from '@easy-expense/ui-web-core';
import { MemberExistsMessage } from '@easy-expense/utils-shared';
import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '../components/Button.components';
import { EmailField } from '../components/Form/Fields/Text/EmailField.component';
import { LabelTextField } from '../components/LabelTextField.component';
import { Modal } from '../components/Shared/Modal.component';
import { WorkspaceMemberTable } from '../components/WorkspaceMembers/WorkspaceMemberTable';
import { auth } from '../firebase/app';

export const Workspace: React.FC = () => {
  return <WorkspaceManagementScreen />;
};

const InviteUserForm: React.FC = () => {
  const { isValid, dirty, isSubmitting, handleSubmit } = useFormikContext();
  const [isFocused, setFocused] = React.useState(false);
  return (
    <Layout.Column bg="navHeaderBackground" px py grow>
      <LabelTextField label="Email" active={isFocused}>
        <EmailField
          name="email"
          placeholder="name@mail.com"
          autoFocus
          weight="bold-700"
          center
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </LabelTextField>
      <Spacer.Vertical size={16} />
      <OpenSans.Secondary>
        {getTranslation(
          'By default users will only be able to see their own expenses. You can change this later.',
        )}
      </OpenSans.Secondary>
      <Spacer.Vertical size={64} />
      <Layout.Column grow />
      <Layout.Row center>
        <Button.Primary
          onClick={() => handleSubmit()}
          active={isValid && dirty && !isSubmitting}
          content="Send Invite"
          variant="bold"
        />
      </Layout.Row>
    </Layout.Column>
  );
};

const InviteButton: React.FC = () => {
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const workspace = useCurrentWorkspace();
  const members = useCachedMembers();
  const [user] = useAuthState(auth);
  const self = members?.find((mem) => mem.userID === user?.uid);
  const [modalOpen, setModalOpen] = React.useState(false);

  if (self && canModifyUsers(self, workspace?.createdBy)) {
    return (
      <>
        <Layout.PressableRow
          onClick={() => setModalOpen(true)}
          bg="inputBackground"
          border={[1, 'solid', 'inputBorder']}
          py={4}
          px
          radius={100}
          align
        >
          <Icon name="add-outline" size={16} color={theme.colors.primary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary>{getTranslation('Invite to Workspace')}</OpenSans.Primary>
          <Spacer.Horizontal size={8} />
          <Icon name="chevron-down" size={16} color={theme.colors.primary} />
        </Layout.PressableRow>

        <Modal showModal={modalOpen}>
          <Formik
            initialValues={{ email: '' }}
            validate={validateFormSchema(z.object({ email: z.string().email() }))}
            onSubmit={async (values) => {
              const {
                data: { success, message },
              } = await addWorkspaceMember(values.email, currentWorkspacePath);

              if (success) {
                setModalOpen(false);
              } else {
                console.error(message);
                alert(MemberExistsMessage === message ? MemberExistsMessage : 'An error occurred');
              }
            }}
            component={InviteUserForm}
          />
          <Button.Primary onClick={() => setModalOpen(false)} content="Cancel" variant="bold" />
        </Modal>
      </>
    );
  }

  return null;
};

const MemberActions: React.FC<{ navigate: ReturnType<typeof useNavigate> }> = ({ navigate }) => (
  <Layout.Row align>
    <OpenSans.Primary size={32} weight="bold-700">
      {getTranslation('Workspace Members')}
    </OpenSans.Primary>
    <Spacer.Horizontal size={32} />
    <InviteButton />
  </Layout.Row>
);

function WorkspaceManagementScreen() {
  const cachedMembers = useCachedMembers();

  const [user] = useAuthState(auth);

  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string | undefined>(undefined);

  return (
    <Layout.Column px>
      <Layout.Column px>
        <Spacer.Vertical size={16} />

        <MemberActions navigate={navigate} />

        <Spacer.Vertical size={24} />

        <Layout.Row>
          <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
            <Layout.Column>
              <OpenSans.Primary weight="bold-700" size="s-16">
                {getTranslation('Admin')}
              </OpenSans.Primary>
              <OpenSans.Primary size="xs-12">
                {getTranslation('Invite users and modify settings')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>

          <Spacer.Horizontal size={16} />

          <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
            <Layout.Column>
              <OpenSans.Primary weight="bold-700" size="s-16">
                {getTranslation('Manager')}
              </OpenSans.Primary>
              <OpenSans.Primary size="xs-12">
                {getTranslation('View and edit all expenses')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>

          <Spacer.Horizontal size={16} />

          <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
            <Layout.Column>
              <OpenSans.Primary weight="bold-700" size="s-16">
                {getTranslation('Member')}
              </OpenSans.Primary>
              <OpenSans.Primary size="xs-12">
                {getTranslation('Only view and edit their own expenses')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>
        </Layout.Row>
      </Layout.Column>

      <Spacer.Vertical size={10} />

      <Separator.Horizontal />

      <WorkspaceMemberTable />
    </Layout.Column>
  );
}
