import { currentUser } from '@easy-expense/auth-client';
import {
  useCachedClients,
  useCachedReports,
  useIsAdminRole,
  useIsManagerRole,
} from '@easy-expense/data-firestore-client';
import { ClientValue, Report } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { seaq } from 'seaq';

import { ReportMaterialTable } from './ReportMaterialTable.component';
import { ReportRow } from './ReportRow.component';

type ReportWithClient = Report & {
  clientValue?: ClientValue;
};

const ReportActions: React.FC<{ navigate: ReturnType<typeof useNavigate> }> = ({ navigate }) => (
  <Layout.Row align>
    <OpenSans.Primary size={32} weight="bold-700">
      {getTranslation('Reports')}
    </OpenSans.Primary>
    <Spacer.Horizontal size={32} />
    <Layout.PressableRow
      onClick={() => navigate('/report')}
      bg="inputBackground"
      border={[1, 'solid', 'inputBorder']}
      py={4}
      px
      radius={100}
      align
    >
      <Icon name="add-outline" size={16} color={theme.colors.primary} />
      <Spacer.Horizontal size={8} />
      <OpenSans.Primary>{getTranslation('Add Report')}</OpenSans.Primary>
    </Layout.PressableRow>
  </Layout.Row>
);

export const ReportList: React.FC<{
  showDownload?: boolean;
}> = () => {
  const navigate = useNavigate();

  return (
    <Layout.Column style={{ width: '100%', height: '100%' }}>
      <Layout.Row style={{ width: '100%', height: '100%' }}>
        <Layout.Column style={{ width: '100%', height: '100%' }}>
          <Spacer.Vertical size={16} />

          <ReportActions navigate={navigate} />

          <Spacer.Vertical size={12} />

          <ReportMaterialTable />
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};
