import { Layout } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';

import { IFrameReloader } from '../IFrameReloader.component';
import LoadingSpinner from '../LoadingSpinner.component';

export const ReportSummaryViewer: React.FC<{
  summaryURL?: string | null;
}> = ({ summaryURL }) => {
  if (!summaryURL) {
    return (
      <Layout.Column style={{ height: '100%', backgroundColor: '#CFD6E0' }} justify>
        <Layout.Row center>
          <LoadingSpinner />
        </Layout.Row>
      </Layout.Column>
    );
  }

  return (
    <Layout.Column style={{ height: '100%', backgroundColor: '#CFD6E0' }} justify>
      <Layout.Column style={{ height: '100%', width: '100%' }} center>
        {(window as DoNotFixMeIAmAny).chrome ? (
          // Chrome will not render the embed component correctly and also automatically downloads the file on load :(
          <IFrameReloader url={summaryURL} />
        ) : (
          <embed
            src={summaryURL}
            width="500px"
            height="1200px"
            type="application/pdf"
            style={{ objectPosition: '50% 50%', objectFit: 'scale-down', margin: 100 }}
          />
        )}
      </Layout.Column>
    </Layout.Column>
  );
};
