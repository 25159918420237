import {
  useCachedReportWithData,
  upsertClient,
  useTopClients,
  useCachedClients,
} from '@easy-expense/data-firestore-client';
import { ReportWithData, ReportWithDataSchema, ReportStatus } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DownloadReportCSVButton } from './DownloadReportCSVButton';
import { DownloadReportPDFButton } from './DownloadReportPDFButton';
import { ReportName } from './ReportName.component';
import { ReportStatusSelector } from './ReportStatus.component';
import { ReportSummaryViewer } from './ReportSummaryViewer.component';
import { TripCard } from './TripCard';
import { Button } from '../Button.components';
import { ExpenseList } from '../Expense/ExpenseList';
import { CloseButton } from '../Shared/CloseButton.component';
import { InsertSuggestionDropdown } from '../Shared/InsertSuggestionDropdown.component';

export const ReportDetail: React.FC<React.PropsWithChildren<{ reportKey?: string }>> = ({
  reportKey,
}) => {
  const navigate = useNavigate();
  const cachedReport = useCachedReportWithData(reportKey);
  const { formatCurrency } = useIntlStore();
  const clients = useCachedClients();
  const topClients = useTopClients();

  const [clientName, setClientName] = React.useState<string | null>(null);
  const [saveURL, setSaveURL] = React.useState<string | null>(null);
  const [report, setReport] = React.useState<Partial<ReportWithData> | null>(cachedReport);
  //done so that the fetchReportSummary is not called when status is updated since it does not appear on the pdf
  const [newStatus, setNewStatus] = React.useState<ReportStatus>(cachedReport?.status ?? 'Unsent');

  React.useEffect(() => {
    if (!cachedReport) {
      const newReportKey = Data.reports.create({
        name: 'New Report',
        status: 'Unsent',
        expenses: [],
        incomes: [],
        trips: [],
      });
      const newReport = Data.reports.getByKey(newReportKey);

      const newReportWithData = ReportWithDataSchema.safeParse({
        ...newReport,
        key: newReportKey,
        tripTotal: 0,
        expenseTotal: 0,
        incomeTotal: 0,
        total: 0,
        reportExpenses: [],
        reportIncomes: [],
        reportTrips: [],
        canEdit: true,
      });

      if (newReportWithData.success) {
        setReport(newReportWithData.data);
        return;
      }

      throw `error creating a new report with data ${JSON.stringify(newReportWithData, null, 2)}`; // open the error page
    }
  }, [cachedReport]);

  const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;

  async function onSaveReport() {
    try {
      const clientKey = clientName
        ? upsertClient({
            name: clientName,
          })
        : undefined;

      if (report?.key) {
        Data.reports.update(report.key, { ...report, client: clientKey, status: newStatus });
      } else {
        Data.reports.create({ ...report, client: clientKey, status: newStatus });
      }

      navigate(`/reports`);
    } catch (err) {
      alert('There was an error saving the report');
    }
  }

  if (!report) {
    return null;
  }

  return (
    <Layout.Column style={{ width: '100%', height: '100vh' }}>
      <Layout.Row>
        {screenWidth > 1000 ? (
          <Layout.Column style={{ maxWidth: 600, height: '100vh' }} grow className="hide-on-mobile">
            <ReportSummaryViewer summaryURL={saveURL} />
          </Layout.Column>
        ) : null}

        <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
          <Layout.Row>
            <Spacer.Flex />
            <Layout.Column style={{ minWidth: 650, maxWidth: 1200, width: '100%' }}>
              <Layout.Row py style={{ width: '70%' }}>
                <ReportName
                  name={report.name}
                  onChange={(name) => {
                    setReport({ ...report, name });
                  }}
                />
              </Layout.Row>

              <Layout.Row py style={{ width: '60%' }}>
                <InsertSuggestionDropdown
                  params={{
                    selectedKey: report.client,
                    suggestionArray: clients,
                    topSuggestion: topClients,
                    headerText: getTranslation('Report Client (Optional)'),
                    placeHolderText: 'Client Name',
                    iconName: 'person-circle-outline',
                    onChange: (clientName: string) => {
                      if (clientName) {
                        setClientName(clientName.trim());
                      }
                    },
                  }}
                />
                <Spacer.Horizontal size={16} />
                <ReportStatusSelector
                  status={newStatus}
                  onChange={(status) => {
                    setNewStatus(status);
                  }}
                />
              </Layout.Row>
              <Spacer.Vertical size={16} />
              <Layout.Row py>
                <OpenSans.Primary size={32}>{getTranslation('Transactions')}</OpenSans.Primary>
                <Spacer.Horizontal size={32} />

                <Layout.PressableRow
                  onClick={() => navigate(`/report/${report.key}/add-expense`)}
                  bg="inputBackground"
                  border={[1, 'solid', 'inputBorder']}
                  px
                  radius={100}
                  align
                >
                  <Icon name="add-outline" size={16} color={theme.colors.primary} />
                  <Spacer.Horizontal size={8} />
                  <OpenSans.Primary>{getTranslation('Add/Remove Transactions')}</OpenSans.Primary>
                </Layout.PressableRow>
              </Layout.Row>

              <Layout.Row py justify="space-between" style={{ width: '100%' }}>
                <Layout.Row px border={[1, 'solid', 'primary']} radius={8} align bg="white">
                  <Icon name="trending-down-outline" size={42} color={theme.colors.primary} />
                  <Spacer.Horizontal size={12} />
                  <Layout.Column>
                    <OpenSans.Primary weight="bold-700" size="s-16">
                      {formatCurrency(Math.abs(report?.total ?? 0))}
                    </OpenSans.Primary>
                    <OpenSans.Primary size="xs-12">{getTranslation('Expenses')}</OpenSans.Primary>
                  </Layout.Column>
                </Layout.Row>

                <Layout.Row>
                  <DownloadReportPDFButton
                    reportID={report.key}
                    saveURL={saveURL}
                    setSaveURL={setSaveURL}
                  />

                  <Spacer.Horizontal size={16} />

                  <DownloadReportCSVButton reportID={report.key} />
                </Layout.Row>
              </Layout.Row>

              <Spacer.Vertical size={16} />

              {report?.expenses && report.expenses.length > 0 && (
                <Layout.Column style={{ width: '100%' }}>
                  <ExpenseList
                    filter={(e) => (report.expenses ?? []).includes(e.key)}
                    showBulk={false}
                    selected={report.expenses}
                    setSelected={() => {}}
                    isReport={true}
                    onRowClick={(expense) => navigate(`/expense/${expense.key}`)}
                    showSearch={false}
                  />
                  <Spacer.Vertical />
                </Layout.Column>
              )}

              {report?.trips && report.trips.length > 0 && (
                <>
                  <TripCard report={report as ReportWithData} />
                  <Spacer.Vertical />
                </>
              )}

              <Spacer.Vertical size={64} />

              <Layout.Row
                py
                style={{
                  justifyContent: 'start',
                  position: 'fixed',
                  bottom: '20px',
                  zIndex: zIndex.SaveButton,
                  minWidth: 600,
                }}
              >
                <Button.Primary
                  onClick={onSaveReport}
                  radius={50}
                  style={{
                    border: `2px solid ${theme.colors.white}`,
                  }}
                >
                  <OpenSans.Custom
                    size={15}
                    weight="bold-700"
                    style={{ color: theme.colors.buttonWhite }}
                  >
                    Save Report
                  </OpenSans.Custom>
                  <Icon
                    size={15}
                    color={theme.colors.buttonWhite}
                    style={{ paddingLeft: 10 }}
                    name="chevron-forward"
                  />
                </Button.Primary>
              </Layout.Row>
            </Layout.Column>
            <Spacer.Flex />
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>
      <CloseButton onClose={() => navigate(`/reports`)} />
    </Layout.Column>
  );
};
