import { useCachedReportWithData } from '@easy-expense/data-firestore-client';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../Button.components';
import { ExpenseList } from '../Expense/ExpenseList';
import { CloseButton } from '../Shared/CloseButton.component';

export const ReportAddTransactions: React.FC<React.PropsWithChildren<{ reportKey?: string }>> = ({
  reportKey,
}) => {
  const navigate = useNavigate();
  const report = useCachedReportWithData(reportKey);
  const [selected, setSelected] = React.useState<string[]>([]);
  const expenses = Data.expenses.use();

  if (!reportKey || !report) {
    navigate('/404');
  }

  React.useEffect(() => {
    const newSelected: string[] = [];
    if (!report) {
      return;
    }
    report.expenses.forEach((expense) => {
      newSelected.push(expense);
    });
    setSelected(newSelected);
  }, []);

  async function onDone() {
    navigate(`/report/${reportKey}`);
  }

  const header = (
    <Layout.Row py align>
      <OpenSans.Primary weight="bold-700" size={32}>
        {getTranslation('Select and add to')} {report?.name}
      </OpenSans.Primary>
      <Spacer.Horizontal size={32} />
      <Layout.Column>
        <Button.Primary radius={100} onClick={onDone}>
          <Icon name="checkmark-done" size={20} color={theme.colors.white} />
          <Spacer.Horizontal size={8} />
          {getTranslation('Done')}
        </Button.Primary>
      </Layout.Column>
    </Layout.Row>
  );

  return (
    <Layout.Row px={24} style={{ height: '100%', width: '100%' }}>
      <Spacer.Flex />
      <Layout.Column style={{ height: '100%', minWidth: 1100 }}>
        <ExpenseList
          onRowClick={(expense) => {
            const newSelected = [...selected];
            const expenseKeys = report?.expenses ? [...report?.expenses] : [];

            if (reportKey) {
              if (selected.includes(expense.key)) {
                newSelected.splice(newSelected.indexOf(expense.key), 1);
                Data.reports.update(reportKey, {
                  expenses: expenseKeys.filter((e) => e !== expense.key),
                });
              } else {
                newSelected.push(expense.key);
                Data.reports.update(reportKey, { expenses: [...expenseKeys, expense.key] });
              }
            }
            setSelected(newSelected);
          }}
          showBulk={true}
          header={header}
          selected={selected}
          setSelected={setSelected}
        />
      </Layout.Column>
      <Spacer.Flex />
      <CloseButton onClose={() => navigate(`/report/${reportKey}`)} />
    </Layout.Row>
  );
};
