import { useWorkspaceKeysStore } from '@easy-expense/data-firestore-client';
import {
  DownloadExpenseRequest,
  DownloadReportRequest,
  Entity,
  EntityName,
} from '@easy-expense/data-schema-v2';
import { setDoc, doc, DocumentReference } from 'firebase/firestore';

import { db } from '../../firebase/app';

export function useCreateRequest<T extends Entity>(entityName: EntityName) {
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  if (!currentWorkspacePath) {
    return;
  }
  return async function createRequest(requestData: T) {
    const reqDoc = doc(
      db,
      `${currentWorkspacePath}/${entityName}s/${requestData.key}`,
    ) as DocumentReference<T>;

    await setDoc(reqDoc, requestData);
    return reqDoc;
  };
}
export function useCreateDownloadRequest() {
  return useCreateRequest<DownloadExpenseRequest>('downloadExpensesRequest');
}
export function useCreateDownloadReportRequest() {
  return useCreateRequest<DownloadReportRequest>('generateReportPDFRequest');
}
