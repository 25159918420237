import { Layout, OpenSans, Separator } from '@easy-expense/ui-web-core';
import { EasyExpenseIssuing } from '@easy-expense/utils-shared';
import React from 'react';

export const ListFooter = React.memo<{
  entity: keyof EasyExpenseIssuing.ClientAPI.GetMethods;
  onClick: () => void;
}>(({ entity, onClick }) =>
  ['sources', 'cardholders'].includes(entity) ? (
    <Layout.PressableColumn onClick={onClick}>
      <Layout.Row px py>
        <OpenSans.Primary>Add New +</OpenSans.Primary>
      </Layout.Row>
      <Separator.Horizontal />
    </Layout.PressableColumn>
  ) : null,
);
