import {
  archiveCategory,
  upsertCategory,
  useCachedCategories,
} from '@easy-expense/data-firestore-client';
import { Category } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import { Checkbox, MenuItem, TextField } from '@mui/material';
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { lighten } from 'polished';
import React from 'react';

import { IconPicker } from './IconPicker.component';

export const CategoriesTable: React.FC<React.PropsWithChildren<object>> = ({}) => {
  const cachedCategories = useCachedCategories();
  const [categories, setCategories] = React.useState<Category[]>(cachedCategories);

  React.useEffect(() => {
    setCategories(cachedCategories);
  }, [cachedCategories]);

  function onCategoryNameChange(newCategoryName: string, existingCategory: Category) {
    const doesSameNameCategoryExist = categories.some(
      (category) => category.value.name === newCategoryName,
    );
    if (doesSameNameCategoryExist) {
      alert(getTranslation('A category with the same name already exists'));
      return;
    }
    const newCategories = categories.map((category) => {
      if (category.key === existingCategory.key) {
        return {
          ...category,
          value: {
            ...category.value,
            name: newCategoryName,
          },
        };
      }
      return category;
    });
    setCategories(newCategories);

    upsertCategory(
      {
        ...existingCategory.value,
        name: newCategoryName,
      },
      { key: existingCategory.key },
    );
  }

  const columns = React.useMemo<MRT_ColumnDef<Category>[]>(
    () => [
      {
        id: 'key',
        header: '',
        maxSize: 10,
        Cell: ({ row }) => {
          return (
            <Layout.Row style={{ width: 32 }}>
              <Layout.PressableRow
                border={[1, 'solid', 'destructiveDark']}
                align
                center
                style={{ backgroundColor: theme.colors.destructiveXLight, width: 32, height: 32 }}
                radius={100}
                onClick={() => {
                  if (!row.original.key) {
                    return;
                  }
                  archiveCategory(row.original.key);
                }}
              >
                <Icon name="trash-outline" size={16} color={theme.colors.destructiveDark} />
              </Layout.PressableRow>
            </Layout.Row>
          );
        },
      },
      {
        id: 'icon',
        header: getTranslation('Icon'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Icon')}</OpenSans.Secondary>
        ),
        maxSize: 60,
        Cell: ({ row }) => {
          return (
            <Layout.Row>
              <IconPicker
                icon={row.original.value.icon ?? ''}
                onChange={(icon) => {
                  upsertCategory(
                    {
                      ...row.original.value,
                      icon,
                    },
                    { key: row.original.key },
                  );
                }}
              />
            </Layout.Row>
          );
        },
      },
      {
        accessorKey: 'value.name',
        header: getTranslation('Category'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Category')}</OpenSans.Secondary>
        ),
        size: 1000,
        Cell: ({ row }) => {
          const categoryColor = hueToColor(row.original.value.color ?? 0);
          return (
            <Layout.Row>
              <Layout.Column
                radius={4}
                py={4}
                style={{
                  backgroundColor: lighten(0.35, `${categoryColor}`),
                }}
              >
                <OpenSans.Input
                  name={`category-${row.original.key}}`}
                  value={row.original.value.name}
                  type="text"
                  weight="light-300"
                  placeholder="New Category"
                  px
                  style={{ outline: 'none' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onCategoryNameChange(event.target.value, row.original);
                  }}
                  size="s-16"
                />
              </Layout.Column>
            </Layout.Row>
          );
        },
      },
      {
        accessorKey: 'value.deductible',
        accessorFn: (row) => (row.value.deductible ? 'tax deductible' : 'non tax deductible'),
        header: getTranslation('Tax Deductible'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Tax Deductible')}</OpenSans.Secondary>
        ),
        muiTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <Layout.Column px align="flex-end" justify="flex-end">
              <Checkbox
                checked={row.original.value.deductible}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const deductible = event.target.checked;
                  upsertCategory(
                    {
                      ...row.original.value,
                      deductible,
                    },
                    { key: row.original.key },
                  );
                }}
              />
            </Layout.Column>
          );
        },
        Filter: (props) => {
          return (
            <TextField
              fullWidth
              margin="none"
              onChange={(e) => props.column.setFilterValue(e.target.value || undefined)}
              placeholder="Filter"
              select
              value={props.column.getFilterValue() ?? ''}
              variant="standard"
            >
              <MenuItem value="none">All</MenuItem>
              <MenuItem value="true">Tax Deductible</MenuItem>
              <MenuItem value="false">Not Tax Deductible</MenuItem>
            </TextField>
          );
        },
        filterFn: (row, _id, filterValue) => {
          if (filterValue === 'none') {
            return true;
          }

          const isTrueSet = filterValue === 'true';
          return row.original.value.deductible === isTrueSet;
        },
      },
    ],
    [categories, cachedCategories],
  );

  const table = useMaterialReactTable({
    columns,
    data: categories,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 25 },
    },
    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
      FilterAltIcon: () => <Icon name="funnel" size={18} color={theme.colors.secondary} />,
      ViewColumnIcon: () => <Icon name="build-outline" size={18} color={theme.colors.secondary} />,
    },
    enableColumnActions: false,
    columnFilterDisplayMode: 'subheader',
    getRowId: (row) => row.key ?? '',
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              borderColor: theme.colors.inputBorder,
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                borderColor: theme.colors.success,
                width: 500,
                paddingRight: 1,
              },
            }}
          />
          <Spacer.Horizontal size={6} />
          <MRT_ShowHideColumnsButton table={table} />
          <Spacer.Horizontal size={6} />

          <MRT_ToggleFiltersButton table={table} />
        </Layout.Row>
      );
    },
  });

  return (
    <Layout.Column>
      <MaterialReactTable table={table} />
    </Layout.Column>
  );
};
