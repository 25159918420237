import { currentUser, StripeClientWrappers } from '@easy-expense/auth-client';
import {
  useIssuingPermissionsStore,
  useIssuingStore,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { Layout, OpenSans, Separator } from '@easy-expense/ui-web-core';
import { EasyExpenseIssuing, EntityIDTypes } from '@easy-expense/utils-shared';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';
// import { FlatList, Linking } from 'react-native';
import { FlatList } from 'react-native';
import { useParams, useNavigate } from 'react-router-dom';
// import { Link, useParams, useNavigate } from 'react-router-dom';

import { AccountDashboard } from '../components/Issuing/AccountDashboard.component';
import { AddNewSwitch } from '../components/Issuing/AddNewSwitch.component';
import { BalanceDashboard } from '../components/Issuing/BalanceDashboard.component';
import { IssuingRowListItem } from '../components/Issuing/IssuingRowListItem.component';
import { ListFooter } from '../components/Issuing/ListFooter.component';

// const entityOptions = [
//   'balance',
//   'account',
//   'cardholders',
//   'cards',
//   'transactions',
//   'authorizations',
//   'sources',
// ] as unknown as (keyof EasyExpenseIssuing.ClientAPI.GetMethods)[];

export const Issuing: React.FC = () => {
  const { currentOrganizationKey } = useWorkspaceKeysStore();
  const organizationID = currentOrganizationKey as EntityIDTypes.EE.OrganizationID;
  const navigate = useNavigate();
  const params = useParams<{ entity: keyof EasyExpenseIssuing.ClientAPI.GetMethods }>();
  const entity: keyof EasyExpenseIssuing.ClientAPI.GetMethods = params.entity || 'account';

  const [addNew, setAddNew] = React.useState<boolean>(false);

  const {
    loadingState,
    account,
    balance,
    refreshEntity,
    cards,
    cardholders,
    sources,
    transactions,
    authorizations,
  } = useIssuingStore();

  const connectedAccountID = useIssuingPermissionsStore(
    (s) => s.connectedAccountID || ('' as EntityIDTypes.ConnectedAccountID),
  );

  const listData: ({ object: string; id: string } & DoNotFixMeIAmAny)[] | undefined = React.useMemo(
    () =>
      entity === 'cards'
        ? cards?.data
        : entity === 'cardholders'
        ? cardholders?.data
        : entity === 'transactions'
        ? transactions?.data
        : entity === 'authorizations'
        ? authorizations?.data
        : entity === 'sources'
        ? sources?.data
        : undefined,
    [entity, cards, cardholders, transactions, authorizations, sources],
  );

  const createConnectedAccount = React.useCallback(() => {
    StripeClientWrappers.Account.updateOrCreateAccount({
      metadata: {
        organizationID: currentOrganizationKey as EntityIDTypes.EE.OrganizationID,
        userID: (currentUser()?.uid ?? '') as EntityIDTypes.EE.UserID,
      },
    }).then(() => {
      navigate('/issuing/onboarding');
    });
  }, [navigate, organizationID]);

  React.useEffect(() => {
    setAddNew(false);
    refreshEntity(entity, {});
  }, [entity]);

  return (
    <>
      <Layout.Column px={16} style={{ height: '100%' }}>
        {/* <Layout.Row>
          {connectedAccountID
            ? entityOptions.map((eO) => (
                <Layout.Column px py bg={eO === entity ? 'brandPrimaryLight' : undefined} key={eO}>
                  <Link
                    to={`/issuing/${eO}`}
                    style={{ textDecoration: 'none', textOverflow: 'ellipsis' }}
                  >
                    <OpenSans.Pressable>{eO}</OpenSans.Pressable>
                  </Link>
                </Layout.Column>
              ))
            : null}
        </Layout.Row> */}
        <Layout.Column px py style={{ flex: 1, overflowY: 'scroll' }}>
          {typeof loadingState === 'string' ? (
            <OpenSans.Primary>{loadingState}</OpenSans.Primary>
          ) : loadingState === true && !connectedAccountID ? (
            <OpenSans.Primary>Loading...</OpenSans.Primary>
          ) : !connectedAccountID ? (
            <OpenSans.Pressable onClick={createConnectedAccount}>
              Sign up for Card Issuing
            </OpenSans.Pressable>
          ) : entity === 'account' && account ? (
            <AccountDashboard account={account} />
          ) : entity === 'balance' && balance ? (
            <BalanceDashboard balance={balance} />
          ) : listData ? (
            <FlatList
              renderItem={({ item }) => (
                <IssuingRowListItem item={item} key={item.id} accountID={connectedAccountID} />
              )}
              data={listData}
              ListFooterComponent={<ListFooter entity={entity} onClick={() => setAddNew(true)} />}
              ListEmptyComponent={
                <Layout.Column>
                  <Layout.Row px py>
                    <OpenSans.Primary>There doesn't seem to be anything here...</OpenSans.Primary>
                  </Layout.Row>
                  <Separator.Horizontal />
                </Layout.Column>
              }
            />
          ) : null}
          {loadingState === true && connectedAccountID ? (
            <Layout.Row px py>
              <OpenSans.Primary>Refreshing...</OpenSans.Primary>
            </Layout.Row>
          ) : null}
        </Layout.Column>
      </Layout.Column>
      {connectedAccountID ? (
        <AddNewSwitch
          modalOpen={addNew}
          setModalOpen={setAddNew}
          entity={entity}
          accountID={connectedAccountID}
        />
      ) : null}
    </>
  );
};
