import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ReportAddTransactions } from '../components/Report/ReportAddTransactions.component';

export const ReportAddTransactionsPage: React.FC = () => {
  const { reportKey } = useParams();
  return (
    <Layout.Column px={16} style={{ height: '100vh', width: '100vw' }}>
      <ReportAddTransactions reportKey={reportKey} />
    </Layout.Column>
  );
};
