import { WebEnv } from '@easy-expense/env-mobile';
import { fetchEphemeralKey } from '@easy-expense/utils-client';
import { EntityIDTypes } from '@easy-expense/utils-shared';
import { FixMe } from '@easy-expense/utils-typescript';
import { loadStripe, StripeElementType } from '@stripe/stripe-js';
import React from 'react';

export const useEphemeralKeyNonce = (
  cardID: EntityIDTypes.CardID,
  divIDPrefix: string,
  accountID: EntityIDTypes.ConnectedAccountID,
) => {
  const mounter = React.useRef<() => void>(() => {});
  React.useEffect(() => {
    (async () => {
      try {
        const stripe = await loadStripe(WebEnv.stripe.publishable_key, {
          apiVersion: '2022-11-15',
        });

        if (!stripe) return;
        // Use Stripe.js to create a nonce
        const nonceResult = await stripe.createEphemeralKeyNonce({
          issuingCard: cardID,
        });
        const nonce = nonceResult.nonce;
        const ephemeralKeyResponse = await fetchEphemeralKey(cardID, accountID);

        const ephemeralKeySecret = ephemeralKeyResponse.secret;
        // seems to work with
        //   <div id="card-number" />
        // although sensitive to mount time
        const stripeCardElements: StripeElementType[] = [
          'issuingCardNumberDisplay',
          'issuingCardCvcDisplay',
          'issuingCardExpiryDisplay',
          'issuingCardPinDisplay',
          'issuingCardCopyButton',
        ];

        // cant really use interval here because async fn
        // inside the useEffect, not returning cleanup fn
        mounter.current = () => {
          setTimeout(() => {
            const isReadyToMount = stripeCardElements.map((e) =>
              document.querySelector('#' + divIDPrefix + e),
            );
            if (isReadyToMount.every((e) => e)) {
              stripeCardElements.forEach((e) => {
                const number = (stripe.elements().create as FixMe)(e, {
                  issuingCard: cardID,
                  ...((e as string) === 'issuingCardCopyButton'
                    ? {}
                    : { nonce, ephemeralKeySecret }),
                });
                number.mount('#' + divIDPrefix + e);
              });
              mounter.current = () => {};
            } else {
              mounter.current();
            }
          }, 100);
        };
        mounter.current();
      } catch (e) {
        console.error(e);
        alert('Could not fetch card details');
        mounter.current = () => {};
      }
    })();
  }, [cardID, divIDPrefix]);
};
