import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Linking, Pressable, Image } from 'react-native';
import { useSearchParams } from 'react-router-dom';

import { useWindowDimensions } from '../hooks/useWindow';

export const InviteAcceptSuccess: React.FC = () => {
  const { width } = useWindowDimensions();
  const small = width < 800;
  const baseImageSize = small ? 60 : 120;
  const [imageStyle, setImageStyle] = React.useState<object | null>(null);
  const [params] = useSearchParams();

  const workspaceLogo = params.get('workspaceLogo');
  const workspaceName = params.get('workspaceName');

  React.useEffect(() => {
    if (workspaceLogo) {
      Image.getSize(workspaceLogo, (width, height) => {
        // TODO @wmatous handle wide images
        const ratio = width / height;
        setImageStyle(
          ratio < 1
            ? { width: baseImageSize, height: baseImageSize / ratio }
            : { width: baseImageSize * ratio, height: baseImageSize },
        );
      });
    }
  }, [params]);

  return (
    <Layout.Column style={{ height: '100vh' }} py px>
      <Layout.Row py>
        <Layout.Column grow>
          <Image
            source={require('../images/icons/logo.png')}
            style={{ height: baseImageSize, width: small ? 161 : 322 }}
          />
        </Layout.Column>
        {workspaceLogo ? (
          <Layout.Column>
            <Image source={{ uri: workspaceLogo }} style={imageStyle} />
          </Layout.Column>
        ) : null}
      </Layout.Row>
      <Layout.Column align>
        <OpenSans.Primary weight="bold-700" size={small ? 'l-20' : '2xl-32'}>
          Invite Accepted!
        </OpenSans.Primary>
        <Spacer.Horizontal size={8} />
        <OpenSans.Primary center weight="regular-400" size={small ? 'l-20' : '2xl-32'}>
          {`Download the app to start tracking${
            workspaceName ? ` in the ${workspaceName} workspace.` : '.'
          }`}
        </OpenSans.Primary>
        <Layout.Row py>
          <Pressable
            onPress={() =>
              Linking.openURL(
                'https://apps.apple.com/app/apple-store/id1528787066?pt=122013942&amp;ct=marketingweb&amp;mt=8',
              )
            }
          >
            <Image
              source={require('../images/icons/app-store-badge.svg')}
              style={{ width: 135, height: 40 }}
              accessibilityLabel="App Store"
            />
          </Pressable>
        </Layout.Row>
        <Spacer.Horizontal size={8} />
        <Layout.Row>
          <Pressable
            onPress={() =>
              Linking.openURL(
                'https://play.google.com/store/apps/details?id=com.easyexpense&amp;utm_source=marketing&amp;utm_medium=web',
              )
            }
          >
            <Image
              accessibilityLabel="Google Play"
              source={require('../images/icons/google-play-badge.svg')}
              style={{ width: 135, height: 40 }}
            />
          </Pressable>
        </Layout.Row>
      </Layout.Column>
    </Layout.Column>
  );
};
