import { getAcceptInviteFn } from '@easy-expense/auth-client';
import { useCachedProfile, useWorkspaceKeysStore } from '@easy-expense/data-firestore-client';
import Data from '@easy-expense/frontend-data-layer';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Button } from './Button.components';
import { TransitionSidebar } from './Transition.component';
import { auth } from '../firebase/app';
import { useAwaitTask } from '../hooks/useAwaitTask';

// TODO @wmatous
const verificationOverride = true;

export const WorkspaceInvite: React.FC = () => {
  const acceptFn = getAcceptInviteFn();
  const [startAccept, accepting] = useAwaitTask(acceptFn);
  const [user] = useAuthState(auth);

  const invites = Data.invites.use();

  const { setCurrentWorkspacePath } = useWorkspaceKeysStore();

  const firstInvite = invites ? invites[0] : null;
  const profile = useCachedProfile(firstInvite?.createdBy ?? '');

  const onChoose = async (inviteID: string, accept: boolean) => {
    try {
      const res = await startAccept({ inviteID, accept });
      const { workspacePath, success } = res.data;
      if (!success) {
        throw new Error();
      }
      if (workspacePath) {
        setCurrentWorkspacePath(workspacePath);
      }
    } catch (e) {
      alert('There was an error');
    }
  };

  if (!firstInvite || !(user?.emailVerified || verificationOverride)) {
    return null;
  }

  // TODO: figure out a different way of doing this if possible
  return (
    <TransitionSidebar modalOpen={!!firstInvite}>
      <Layout.Column px bg="navHeaderBackground" grow>
        <Layout.Column align py center grow>
          <OpenSans.Primary size={24} weight="bold-700">
            Workspace Invite
          </OpenSans.Primary>
          <Spacer.Vertical size={12} />
          <OpenSans.Primary size={64}>📬</OpenSans.Primary>
          <Spacer.Vertical />
          <OpenSans.Primary center>You've been invited to join</OpenSans.Primary>
          <OpenSans.Primary center weight="bold-700" size="xl-28">
            {firstInvite.workspaceName}
          </OpenSans.Primary>
          {profile ? <OpenSans.Primary center>by {profile.displayName}</OpenSans.Primary> : null}

          <Spacer.Vertical size={32} />

          <Layout.Row px={32}>
            <OpenSans.Primary size={24}>❌</OpenSans.Primary>
            <Spacer.Horizontal size={32} />
            <OpenSans.Primary size="s-16">
              {firstInvite?.workspaceName} will NOT be able to view: expenses added to other
              workspaces or unreviewed expenses from linked accounts
            </OpenSans.Primary>
          </Layout.Row>

          <Spacer.Vertical size={16} />

          <Layout.Row px={32}>
            <OpenSans.Primary size={24}>✅</OpenSans.Primary>
            <Spacer.Horizontal size={16} />
            <OpenSans.Primary size="s-16">
              {firstInvite?.workspaceName} WILL be able to view: expenses added to the workspace
            </OpenSans.Primary>
          </Layout.Row>
        </Layout.Column>

        <Spacer.Vertical size={48} />

        <Button.Primary
          content={`Join ${firstInvite.workspaceName}`}
          variant="bold"
          active={!accepting}
          onClick={() => {
            onChoose(firstInvite?.key ?? '', true);
          }}
        />
        <Spacer.Vertical />
        <Layout.PressableRow
          center
          py
          onClick={() => {
            onChoose(firstInvite?.key ?? '', false);
          }}
        >
          <OpenSans.Pressable size="s-16" weight="bold-700">
            Don't join workspace
          </OpenSans.Pressable>
        </Layout.PressableRow>
        <Spacer.Vertical />
      </Layout.Column>
    </TransitionSidebar>
  );
};
