import { entityFields } from '@easy-expense/data-firestore-shared';
import {
  DownloadRequestFilterSchema,
  DownloadRequestFilter,
  ExportType,
  SummaryUnit,
  ExportTypeValue,
  validateFormSchema,
  DownloadExpenseRequestSchema,
} from '@easy-expense/data-schema-v2';
import { Layout, OpenSans, Spacer, Separator } from '@easy-expense/ui-web-core';
import { onSnapshot, doc, getDocFromServer } from '@firebase/firestore';
import { Formik, Field } from 'formik';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { z } from 'zod';

import { FieldError } from '../components/Form/Fields/ErrorText.component';
import { EmailField } from '../components/Form/Fields/Text/EmailField.component';
import { LabelTextField } from '../components/LabelTextField.component';
import { auth, db } from '../firebase/app';
import { useCreateDownloadRequest } from '../hooks/firebase/useDownloadRequest';

export const Export: React.FC = () => {
  return <CreateExportRequestForm />;
};

type FilterValues = DownloadRequestFilter & {
  allDates: boolean;
  email: boolean;
  exportType: ExportTypeValue;
  recipientEmail: string;
};

const RightAlignedField: React.FC<React.PropsWithChildren<{ label: string; name: string }>> = (
  props,
) => {
  const { label, name, children } = props;
  return (
    <Layout.Column>
      <Layout.Column>
        <Layout.Row justify="space-between" py={4}>
          <OpenSans.Primary>{label}</OpenSans.Primary>
          <Spacer.Horizontal />
          {children}
        </Layout.Row>
        <Separator.Horizontal />
        <FieldError name={name} />
      </Layout.Column>
      <Spacer.Vertical units={2} />
    </Layout.Column>
  );
};

const CreateExportRequestForm = () => {
  const create = useCreateDownloadRequest();
  const [user] = useAuthState(auth);
  const [working, setWorking] = React.useState(false);
  const [shareURL, setShareURL] = React.useState<string | null>(null);

  return user ? (
    <Layout.Column grow center>
      <Formik
        initialValues={
          {
            // TODO: we can probably do this with date-fns
            dateStart: new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0],
            dateEnd: new Date().toISOString().split('T')[0],
            summaryUnit: SummaryUnit.Values.month,
            includeArchived: false,
            includeAttachments: false,
            allDates: false,
            email: true,
            exportType: ExportType.Values.Expenses,
            recipientEmail: user?.email,
          } as FilterValues
        }
        validate={(values) => {
          const errors = {
            ...validateFormSchema(DownloadRequestFilterSchema)(values),
            ...(new Date(values.dateStart ?? new Date()) > new Date(values.dateEnd ?? new Date())
              ? { dateStart: 'Start date must come before end date' }
              : {}),
            ...(z.string().email().safeParse(values.recipientEmail).success
              ? {}
              : { recipientEmail: 'Must be a valid email' }),
          };
          return errors;
        }}
        onSubmit={async (_filter) => {
          if (shareURL) {
            window.open(shareURL);
            setShareURL(null);
            return;
          }
          try {
            setWorking(true);
            const filter = {
              dateStart: _filter.allDates
                ? null
                : new Date(_filter.dateStart ?? new Date()).toISOString(),
              dateEnd: _filter.allDates
                ? null
                : new Date(_filter.dateEnd ?? new Date()).toISOString(),
              summaryUnit: _filter.summaryUnit,
              includeArchived: _filter.includeArchived,
              includeAttachments: _filter.includeAttachments,
            } as const;
            if (!create) {
              alert('An error occurred, please try again');
            } else {
              const reqDoc = await create(
                DownloadExpenseRequestSchema.parse({
                  exportType: _filter.exportType,
                  recipientEmail: _filter.email ? _filter.recipientEmail : null,
                  filter,
                  ...entityFields('create', user.uid),
                }),
              );
              if (_filter.email ? _filter.recipientEmail : null) {
                alert(`Success! The data will be emailed to ${_filter.recipientEmail}`);
              } else {
                onSnapshot(reqDoc, {
                  next: async (snap) => {
                    const newData = snap.data();
                    if (newData?.error) {
                      alert('There was an error exporting your data');
                    }
                    if (newData?.sharedResourceId) {
                      const shareURL = (
                        await getDocFromServer(
                          doc(db, `sharedResources/${newData?.sharedResourceId}`),
                        )
                      ).get('shareURL');
                      const shareWindow = window.open(shareURL);
                      if (
                        !shareWindow ||
                        shareWindow.closed ||
                        typeof shareWindow.closed == 'undefined'
                      ) {
                        setShareURL(shareURL);
                      }
                      setWorking(false);
                    }
                  },
                  error: () => {
                    setWorking(false);
                    alert('There was an error creating the request');
                  },
                });
              }
            }
          } catch (e) {
            setWorking(false);
            alert('There was an error');
          }
        }}
        component={(props) => (
          <_CreateExportRequestForm
            {...props}
            working={working}
            shareURL={shareURL}
            style={{ width: '100%' }}
          />
        )}
      />
    </Layout.Column>
  ) : null;
};

const _CreateExportRequestForm = ({
  handleSubmit,
  working,
  shareURL,
  values,
}: {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  working: boolean;
  shareURL: string | null;
  values: FilterValues;
  style: object;
}) => {
  return (
    <Layout.Column py px={24} style={{ maxWidth: 500, width: '100%' }}>
      <Field as="select" name="exportType">
        {[ExportType.Values.Trips, ExportType.Values.Expenses].map((e) => (
          <option key={e} value={e}>
            {e}
          </option>
        ))}
      </Field>
      <Spacer.Vertical size="s-16" />
      <RightAlignedField label="Include archived" name="date">
        <Field type="checkbox" name="includeArchived" />
      </RightAlignedField>
      {values.exportType === ExportType.Values.Expenses ? (
        <RightAlignedField label="Include receipts" name="date">
          <Field type="checkbox" name="includeAttachments" />
        </RightAlignedField>
      ) : null}
      <RightAlignedField label="Include all dates" name="date">
        <Field type="checkbox" name="allDates" />
      </RightAlignedField>

      <Spacer.Vertical size="s-16" />

      <RightAlignedField label="Start Date" name="dateStart">
        <Field type="date" name="dateStart" disabled={values.allDates} />
      </RightAlignedField>
      <RightAlignedField label="End Date" name="dateEnd">
        <Field type="date" name="dateEnd" disabled={values.allDates} />
      </RightAlignedField>

      <Spacer.Vertical size="s-16" />

      <Layout.Column>
        <RightAlignedField label="Send me the results" name="email">
          <Field type="checkbox" name="email" />
        </RightAlignedField>
        <LabelTextField label="Recipient Email">
          <EmailField name="recipientEmail" placeholder="name@email.com" showErrors />
        </LabelTextField>
      </Layout.Column>

      <Spacer.Vertical size="s-16" />

      <Layout.PressableRow
        onClick={() => {
          if (shareURL) {
            window.open(shareURL);
          } else {
            handleSubmit();
          }
        }}
        disabled={working}
        py
        center
        type="submit"
        bg={!working ? 'brandPrimary' : 'placeholder'}
        border={[0, 'solid', 'gray']}
        radius={4}
      >
        <OpenSans.Inverse weight="bold-700">
          {shareURL ? 'Save File' : !working ? `Download ${values.exportType}` : 'Please Wait'}
        </OpenSans.Inverse>
      </Layout.PressableRow>
    </Layout.Column>
  );
};
