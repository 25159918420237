import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { useField } from 'formik';
import React from 'react';

import { FieldError } from '../ErrorText.component';
import { TextFieldProps } from '../props';

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { name, showErrors, formatter, showClearButton, size, children, ...rest } = props;
  const [field, , helpers] = useField<string>(name);
  const { value } = field;
  const { setValue, setTouched } = helpers;

  return (
    <Layout.Column grow>
      <Layout.Row align>
        <Layout.Row grow>
          <OpenSans.Input
            ref={ref}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const text = e.target.value;
              const newValue = formatter ? formatter(value, text) : text;
              setValue(newValue);
              setTimeout(() => {
                setTouched(true);
              });
            }}
            weight="light-300"
            value={value}
            style={{ flexGrow: 1, outline: 'none' }}
            size={size}
            {...rest}
          />
          {children}
        </Layout.Row>
        {/* {showClearButton && !!value && (
          <ClearButton
            textColor={rest.color ?? 'primary'}
            onPressClear={() => {
              setValue('');
              setTimeout(() => {
                setTouched(true);
              });
            }}
            multiline={multiline}
          />
        )} */}
      </Layout.Row>
      {!!showErrors && <FieldError {...{ name }} />}
    </Layout.Column>
  );
});
