import { useCachedPaymentMethods } from '@easy-expense/data-firestore-client';
import { PaymentMethod } from '@easy-expense/data-schema-v2';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const PaymentMethodField: React.FC<
  React.PropsWithChildren<{
    initialPaymentMethodKey?: string | null;
    onChange: (paymentMethodKey: string) => void;
  }>
> = ({ initialPaymentMethodKey, onChange }) => {
  const paymentMethods: PaymentMethod[] = useCachedPaymentMethods();
  const [paymentMethodKey, setPaymentMethodKey] = React.useState(initialPaymentMethodKey ?? '');

  function onPaymentMethodChange(value: string) {
    setPaymentMethodKey(value);
    onChange(value);
  }

  return (
    <LabelTextField label="Payment Method (optional)" active={false}>
      <Layout.Row
        justify
        center
        style={{
          fontFamily: 'Poppins_Light',
          position: 'relative',
        }}
      >
        <Icon name="card-outline" size={20} style={{ paddingRight: 10 }} />
        <select
          value={paymentMethodKey}
          onChange={(event) => onPaymentMethodChange(event.target.value)}
          style={{
            border: 'none',
            width: '100%',
            background: 'transparent',
            zIndex: 1,
          }}
        >
          <option value="">Select a payment method</option>
          {paymentMethods.map((paymentMethod) => (
            <option key={paymentMethod.key} value={paymentMethod.key}>
              {paymentMethod.value.name}
            </option>
          ))}
        </select>
        <span
          style={{
            position: 'absolute',
            left: 'calc(100% - 20px)',
            top: 2,
            zIndex: 0,
          }}
        >
          <Icon name="chevron-down" size={16} iconColor="primary" />
        </span>
      </Layout.Row>
    </LabelTextField>
  );
};
