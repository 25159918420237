import { StripeClientWrappers } from '@easy-expense/auth-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { EntityIDTypes, Wrapped } from '@easy-expense/utils-shared';
import React from 'react';

export const CardStatusButtons: React.FC<{
  card: Wrapped.Card;
  accountID: EntityIDTypes.ConnectedAccountID;
}> = ({ card, accountID }) => {
  const [toggling, setToggling] = React.useState<boolean>(false);
  const [cancelling, setCancelling] = React.useState<boolean>(false);
  const toggleCard = React.useCallback(() => {
    setToggling(true);
    (card.status === 'active'
      ? StripeClientWrappers.Cards.deactivateCard
      : StripeClientWrappers.Cards.activateCard)(accountID, card)
      // TODO: async/await all over
      .catch((e) => {
        console.error(e);
        alert('There was an error');
      })
      .finally(() => {
        setToggling(false);
      });
  }, []);
  const cancelCard = React.useCallback(() => {
    setCancelling(true);
    // TODO change reason
    StripeClientWrappers.Cards.cancelCard(accountID, card, 'lost')
      .catch((e) => {
        console.error(e);
        alert('There was an error');
      })
      .finally(() => {
        setCancelling(false);
      });
  }, []);
  return (
    <Layout.Row justify="flex-end">
      {/* TODO @eli show card status and active/inactive */}
      <Layout.PressableColumn py={2} px radius disabled={toggling} onClick={toggleCard}>
        <OpenSans.Pressable>
          {toggling ? 'Working' : card.status === 'active' ? 'Deactivate' : 'Activate'}
        </OpenSans.Pressable>
      </Layout.PressableColumn>
      <Spacer.Horizontal />
      <Layout.PressableColumn py={2} px radius disabled={cancelling} onClick={cancelCard}>
        <OpenSans.Pressable>{cancelling ? 'Working' : 'Cancel Card'}</OpenSans.Pressable>
      </Layout.PressableColumn>
    </Layout.Row>
  );
};
