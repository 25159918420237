import {
  canChangeUserRole,
  useCachedMembers,
  useCurrentWorkspace,
  useWorkspaceStore,
} from '@easy-expense/data-firestore-client';
import {
  Invite,
  OrganizationMember,
  Profile,
  UserRole,
  UserRoleSchema,
  UserStatusSchema,
  WorkspaceMember,
} from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../firebase/app';

export type WorkspaceRow = {
  key?: string;
  userID?: string;
  displayName: string;
  role: string;
  email: string;
  status: status;
  lastActive: number;
  updateOptions?: UserRole[];
};

export enum status {
  Active = 'Active',
  Invited = 'Invited',
}

function getMembershipRole(params: {
  member: WorkspaceMember;
  orgMember?: OrganizationMember;
  owner: string;
}) {
  const { member, orgMember, owner } = params;

  return member.userID === owner
    ? 'Owner'
    : orgMember?.status === UserStatusSchema.Values.active
    ? member.role.charAt(0).toUpperCase() + member.role.slice(1)
    : orgMember?.status ?? 'Member';
}

export function useWorkspaceRows() {
  const members = useCachedMembers();
  const invites: Invite[] = Data.workspaceInvites.use();

  const workspace = useCurrentWorkspace();
  const profiles = useWorkspaceStore((state) => state.profiles);

  const organizationMembers = useWorkspaceStore((s) => s.organizationMembers);
  const [user] = useAuthState(auth);

  return React.useMemo(() => {
    const self = members?.find((mem) => mem.userID === user?.uid);

    const res: WorkspaceRow[] = [
      ...members.map((member) => {
        const profile: Profile | undefined = profiles[member.userID];
        const orgMember = organizationMembers[member.userID];
        const updateOptions =
          orgMember?.status === UserStatusSchema.Values.active &&
          self &&
          canChangeUserRole(member, self, workspace.createdBy)
            ? UserRoleSchema.options.filter((el) => el !== member.role)
            : [];

        return {
          key: member.key,
          userID: member.userID,
          displayName: getUserDisplayName(profile, member),
          role: getMembershipRole({ member, orgMember, owner: workspace.createdBy }),
          email: profile?.email ?? 'No Email',
          status: status.Active,
          lastActive: profile?.updatedAt ?? 0,
          updateOptions,
        };
      }),
      ...invites.map((invite) => {
        return {
          key: invite.key,
          userID: undefined,
          displayName: getUserDisplayName({ email: invite.email }),
          role: 'Member',
          email: invite.email,
          status: status.Invited,
          lastActive: invite.updatedAt,
          updateOptions: [],
        };
      }),
    ];

    return res;
  }, [members, organizationMembers, invites]);
}
