import { StripeClientWrappers } from '@easy-expense/auth-client';
import { useIssuingStore } from '@easy-expense/data-firestore-client';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { EntityIDTypes, Wrapped } from '@easy-expense/utils-shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthorizationStatusButton: React.FC<{
  authorization: Wrapped.Authorization;
  accountID: EntityIDTypes.ConnectedAccountID;
}> = ({ authorization, accountID }) => {
  const navigate = useNavigate();
  const [working, setWorking] = React.useState<boolean>(false);
  const blocked = authorization.status === 'closed' && !authorization.approved;

  const isAllowed = !!authorization.metadata.allowed;
  const formattedStatus =
    authorization.status[0].toUpperCase() +
    authorization.status.slice(1) +
    ' - ' +
    (authorization.approved ? 'Allowed' : 'Blocked');
  const toggleAllowed = React.useCallback(() => {
    const shouldAllow = !isAllowed;
    setWorking(true);
    StripeClientWrappers.Authorizations.allowAuthorization(
      accountID,
      authorization,
      shouldAllow ? 'allowed' : '',
    )
      .then(() => {
        useIssuingStore.getState().refreshEntity('authorizations', {});
      })
      .catch((e) => {
        console.error(e);
        alert('There was an error');
      })
      .finally(() => {
        setWorking(false);
      });
  }, [navigate]);

  return (
    <Layout.Row>
      {blocked ? (
        <Layout.PressableColumn
          bg={isAllowed ? 'warningLight' : 'destructiveLight'}
          py={2}
          px
          radius
          disabled={!blocked || working}
          onClick={toggleAllowed}
        >
          <OpenSans.Primary color={isAllowed ? 'destructive' : 'warning'}>
            {working ? 'Working' : isAllowed ? 'Block' : 'Allow'}
          </OpenSans.Primary>
        </Layout.PressableColumn>
      ) : null}
      <OpenSans.Primary px>{formattedStatus}</OpenSans.Primary>
    </Layout.Row>
  );
};
