import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const DateField: React.FC<
  React.PropsWithChildren<{
    error?: boolean;
    initialDate?: string;
    onChange: (date: string) => void;
    disabled?: boolean;
  }>
> = ({ error, initialDate, onChange, disabled }) => {
  const today = new Date().toLocaleDateString('en-ca'); // yyyy-mm-dd
  const [date, setDate] = React.useState<string>(initialDate ?? today);

  React.useEffect(() => {
    onChange(date);
  }, [date]);

  return (
    <LabelTextField label="Date" error={error} active={false}>
      <Layout.Row justify center style={{ width: '100%', position: 'relative' }}>
        <Icon name="calendar-outline" size={20} style={{ paddingRight: 10 }} />
        <OpenSans.Input
          className="date-picker"
          name="date"
          value={date}
          disabled={disabled}
          type="date"
          weight="light-300"
          placeholder="Today"
          style={{
            flexGrow: 1,
            outline: 'none',
            width: '100%',
            border: 'none',
            background: 'transparent',
            zIndex: 1,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDate(event.target.value)}
        />

        <Icon name="chevron-down" size={16} iconColor="primary" />
        <Spacer.Horizontal size={2} />
      </Layout.Row>
    </LabelTextField>
  );
};
