import { useIntlStore } from '@easy-expense/intl-client';
import { Layout, OpenSans, Separator, Spacer } from '@easy-expense/ui-web-core';
import {
  assert,
  EntityIDTypes,
  StripeToEEParse,
  StripeToString,
  Wrapped,
} from '@easy-expense/utils-shared';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

import { AuthorizationStatusButton } from './AuthorizationStatusButton.component';
import { CardDetailsRow } from './CardDetailsRow.component';
import { CardIssueButton } from './CardIssueButton.component';
import { CardStatusButtons } from './CardStatusButtons.component';
import { FundButton } from './FundButton.component';

export const IssuingRowListItem: React.FC<{
  item: { object: string; id: string } & DoNotFixMeIAmAny;
  accountID: EntityIDTypes.ConnectedAccountID;
}> = ({ item, accountID }) => {
  const { formatCurrency } = useIntlStore();
  switch (item.object) {
    case 'issuing.authorization': {
      assert<Wrapped.Authorization>(item);
      return (
        <Layout.Column key={item.id}>
          <Layout.Row px py>
            <OpenSans.Primary>{item.merchant_data.name}</OpenSans.Primary>
            <OpenSans.Primary px weight="bold-700">
              {formatCurrency(StripeToEEParse.amountToTotal(item.amount, item.currency))}
            </OpenSans.Primary>
            <Spacer.Flex />
            <AuthorizationStatusButton authorization={item} accountID={accountID} />
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      );
    }
    case 'issuing.transaction': {
      assert<Wrapped.Transaction>(item);
      return (
        <Layout.Column key={item.id}>
          <Layout.Row px py>
            <OpenSans.Primary>{item.merchant_data.name}</OpenSans.Primary>
            <OpenSans.Primary px weight="bold-700">
              {formatCurrency(StripeToEEParse.transactionAmountToTotal(item.amount, item.currency))}
            </OpenSans.Primary>
            <Spacer.Flex />
            {/* <OpenSans.Pressable>{item.cardholder}</OpenSans.Pressable> */}
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      );
    }
    case 'issuing.card': {
      assert<Wrapped.Card>(item);
      return (
        <Layout.Column key={item.id}>
          <Layout.Row px py>
            <OpenSans.Primary>{item.brand}</OpenSans.Primary>
            <OpenSans.Secondary px>{StripeToString.Card.last4WithExpiry(item)}</OpenSans.Secondary>
            <Spacer.Flex />
            <OpenSans.Pressable>{item.cardholder.name}</OpenSans.Pressable>
          </Layout.Row>
          <Layout.Row px py>
            <Layout.Column grow>
              <CardDetailsRow card={item} accountID={accountID} />
            </Layout.Column>
            <Layout.Column grow>
              <CardStatusButtons card={item} accountID={accountID} />
            </Layout.Column>
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      );
    }
    case 'issuing.cardholder': {
      assert<Wrapped.Cardholder>(item);
      return (
        <Layout.Column key={item.id}>
          <Layout.Row px py>
            <OpenSans.Primary>{item.name}</OpenSans.Primary>
            <OpenSans.Secondary px>{item.email}</OpenSans.Secondary>
            <Spacer.Flex />
            <CardIssueButton cardholder={item} accountID={accountID} />
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      );
    }
    case 'source': {
      assert<Wrapped.Source>(item);
      return (
        <Layout.Column key={item.id}>
          <Layout.Row px py>
            <OpenSans.Primary>{StripeToString.Source.displayName(item)}</OpenSans.Primary>
            <OpenSans.Secondary px>{item.status}</OpenSans.Secondary>
            <Spacer.Flex />
            <FundButton source={item} accountID={accountID} />
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      );
    }
    default: {
      return (
        <Layout.Column key={item.id}>
          <Layout.Row px py>
            <OpenSans.Primary>{item.id}</OpenSans.Primary>
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      );
    }
  }
};
