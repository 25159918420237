import { Suggestion } from '@easy-expense/data-schema-v2';
import React from 'react';

import {
  BaseSuggestionDropdown,
  SuggestionDropdownParams,
} from './BaseSuggestionDropdown.component';

export const SearchSuggestionDropdown: React.FC<
  React.PropsWithChildren<{ params: SuggestionDropdownParams<Suggestion> }>
> = ({ params }) => {
  const { suggestionArray, selectedKey, onChange } = params;

  const [suggestions, setSuggestions] = React.useState<Suggestion[]>(suggestionArray);
  const [suggestionName, setSuggestionName] = React.useState(
    suggestionArray.find((v) => v.key === selectedKey)?.value?.name ?? '',
  );
  const [prevSuggestionName, setPrevSuggestionName] = React.useState(suggestionName);

  function onInputFocus() {
    if (suggestionName === '') {
      setPrevSuggestionName(prevSuggestionName);
    } else {
      setSuggestionName('');
    }
  }

  function onInputBlur() {
    const exists = suggestionArray.some(
      (entity: Suggestion) => suggestionName && entity.value.name === suggestionName,
    );
    if (!exists) {
      if (prevSuggestionName) {
        setSuggestionName(prevSuggestionName);
      } else {
        setSuggestionName('');
      }
      return;
    }
    setPrevSuggestionName(suggestionName);
    onChange(suggestionName);
  }

  return (
    <BaseSuggestionDropdown
      params={{
        ...params,
        suggestionName,
        setSuggestionName,
        suggestions,
        setSuggestions,
        onInputFocus,
        onInputBlur,
        allowCreateNew: false,
        onChange: () => {},
      }}
    />
  );
};
