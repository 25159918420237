import { Analytics } from '@easy-expense/analytics-client';
import { useCurrentWorkspace } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon, IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { WorkspaceType } from '@easy-expense/utils-shared';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { OnboardingActionColumn, OnboardingBackdrop } from './OnboardingBackdrop.component';
import { WorkspaceLoader } from './WorkspaceLoading.component';
import { auth } from '../../firebase/app';
import { useWindowDimensions } from '../../hooks/useWindow';
import { createWorkspace } from '../../lib/createWorkspace';
import { Button } from '../Button.components';

type WorkspaceOption = {
  title: string;
  subtitle: string;
  icon: IconName;
  selected: boolean;
  type: WorkspaceType;
};

const WORKSPACE_OPTIONS: WorkspaceOption[] = [
  {
    title: 'Personal Budget',
    subtitle: 'Personal Budgeting',
    icon: 'pie-chart',
    selected: false,
    type: 'personal',
  },
  {
    title: 'Submit Expense Reports',
    subtitle: 'Create PDF reports and get reimbursed',
    icon: 'document-text',
    selected: false,
    type: 'employee',
  },
  {
    title: 'Tax Deductions',
    subtitle: 'Maximize your tax savings',
    icon: 'pricetag',
    selected: false,
    type: 'business',
  },
  {
    title: 'Team Tracking',
    subtitle: 'Invite your employees and track together',
    icon: 'people',
    selected: false,
    type: 'team',
  },
];

export const HowDoYouWantToUse: React.FC<React.PropsWithChildren<object>> = () => {
  const isMobile = useWindowDimensions();
  const [workspaceOptions, setWorkspaceOptions] = React.useState(WORKSPACE_OPTIONS);
  const workspace = useCurrentWorkspace();
  const [isCreatingWorkspace, setIsCreatingWorkspace] = React.useState<boolean>(false);
  const [navigationPath, setNavigationPath] = React.useState<string>('/onboarding/get-started');
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  React.useEffect(() => {
    if (user && workspace) {
      navigate(navigationPath);
    }
  }, [navigate, user, workspace]);

  const optionHasBeenSelected = React.useMemo(() => {
    return workspaceOptions.filter((wo) => wo.selected).length;
  }, [workspaceOptions]);

  const selectOptions = (option: WorkspaceOption) => {
    const updatedOptions = workspaceOptions.map((workSpaceOption) =>
      workSpaceOption.title === option.title
        ? { ...workSpaceOption, selected: !workSpaceOption.selected }
        : workSpaceOption,
    );
    setWorkspaceOptions(updatedOptions);
  };

  const onContinue = async () => {
    const selectedOptions = workspaceOptions.filter((wo) => wo.selected);
    if (!selectedOptions.length) {
      return;
    }

    setIsCreatingWorkspace(true);
    Analytics.track('onboarding_how_do_you_want_to_use_options', {
      options: selectedOptions.map((s) => s.title),
    });
    if (selectedOptions.find((so) => so.title === 'Team Tracking')) {
      setNavigationPath('/teams/pay/TEAMS_BASIC');
    }
    if (selectedOptions.length === 1 && selectedOptions.find((so) => so.type === 'personal')) {
      await createWorkspace('personal');
      return;
    }
    await createWorkspace('business');
  };

  if (isCreatingWorkspace) {
    return (
      <OnboardingBackdrop>
        <WorkspaceLoader />
      </OnboardingBackdrop>
    );
  }

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn>
        <OpenSans.Primary center weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
          {getTranslation('How do you want to use Easy Expense?')}
        </OpenSans.Primary>
        <Spacer.Vertical size={48} />
        {workspaceOptions.length &&
          workspaceOptions.map((option) => {
            return (
              <>
                <Layout.PressableRow
                  py
                  px
                  align
                  justify="space-between"
                  border={
                    option.selected ? [2, 'solid', 'brandPrimary'] : [2, 'solid', 'inputBorder']
                  }
                  bg="inputBackground"
                  radius={10}
                  style={{ width: isMobile ? '100%' : 400 }}
                  onClick={() => {
                    selectOptions(option);
                  }}
                >
                  <Icon
                    name={`${option.icon}${option.selected ? '' : '-outline'}` as IconName}
                    color={option.selected ? theme.colors.brandPrimary : undefined}
                    size={44}
                  />
                  <Spacer.Horizontal />
                  <Layout.Column grow>
                    <OpenSans.Primary weight="bold-700" size={20}>
                      {getTranslation(option.title)}
                    </OpenSans.Primary>
                    <OpenSans.Primary size={14}>{getTranslation(option.subtitle)}</OpenSans.Primary>
                  </Layout.Column>
                </Layout.PressableRow>
                <Spacer.Vertical size={16} />
              </>
            );
          })}
        <Spacer.Vertical size={48} />

        <Button.Primary
          px
          py
          radius
          active={optionHasBeenSelected}
          center
          style={{ width: isMobile ? '100%' : 350 }}
          content={
            optionHasBeenSelected
              ? getTranslation('Continue')
              : getTranslation('Select all that apply')
          }
          onClick={onContinue}
        />
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
