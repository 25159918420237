import { Suggestion } from '@easy-expense/data-schema-v2';
import { Icon, IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, zIndex } from '@easy-expense/ui-web-core';
import { FixMe } from '@easy-expense/utils-typescript';
import React from 'react';

import { LabelTextField } from '../LabelTextField.component';
import { Circle } from '../Shape.components';

export type SuggestionDropdownParams<T extends Suggestion> = {
  selectedKey?: string | null | undefined;
  suggestionArray: T[];
  topSuggestion: T[];
  headerText: string;
  placeHolderText: string;
  iconName: IconName;
  error?: boolean;
  onChange: (suggestionName: string) => void;
};

export type BaseSuggestionDropdownParams<T extends Suggestion> = SuggestionDropdownParams<T> & {
  onInputFocus: () => void;
  onInputBlur: () => void;
  suggestionName: string;
  setSuggestionName: (value: string) => void;
  suggestions: T[];
  setSuggestions: (value: T[]) => void;
  allowCreateNew?: boolean;
};

const MAX_DISPLAYED_SUGGESTIONS = 10;

export const BaseSuggestionDropdown: React.FC<
  React.PropsWithChildren<{ params: BaseSuggestionDropdownParams<Suggestion> }>
> = ({ params }) => {
  const {
    selectedKey,
    suggestionArray,
    topSuggestion,
    error,
    headerText,
    onChange,
    placeHolderText,
    iconName,
    allowCreateNew = true,
    onInputFocus,
    onInputBlur,
    suggestionName,
    setSuggestionName,
    setSuggestions,
    suggestions,
  } = params;

  const [isExisting, setIsExisting] = React.useState(true);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [borderStyles, setBorderStyles] = React.useState({});
  const [showList, setShowList] = React.useState(false);
  const [logo, setLogo] = React.useState(findSuggestionImage(suggestionName) ?? '');
  const [icon, setIcon] = React.useState<string | undefined>(undefined);
  const [flipDropdown, setFlipDropdown] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    const checkPosition = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        setFlipDropdown(rect.top > windowHeight / 2);
      }
    };
    checkPosition();
    window.addEventListener('scroll', checkPosition, true);
    return () => {
      window.removeEventListener('scroll', checkPosition, true);
    };
  }, []);

  React.useEffect(() => {
    setSuggestionName(suggestions.find((v) => v.key === selectedKey)?.value?.name ?? '');
  }, [selectedKey]);

  React.useEffect(() => {
    setSuggestions(suggestionArray);
  }, [suggestionArray]);

  React.useEffect(() => {
    if (showList) {
      setBorderStyles({ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 });
    } else {
      inputRef.current?.blur();
      tryToUpdateIcon();

      setBorderStyles({});
    }
  }, [showList]);

  React.useEffect(() => {
    setSuggestions([]);
    if (!suggestionName) {
      setIcon(undefined);
      setSuggestions(topSuggestion);
    } else if (suggestionArray.length) {
      setSuggestions(
        suggestionArray.filter((v) =>
          v.value.name.toLowerCase().includes(suggestionName.toLowerCase()),
        ),
      );
    }
    onChange(suggestionName);
    checkExists();
  }, [suggestionName]);

  function checkExists(value?: string) {
    const compareString = value ?? suggestionName;

    const exists = suggestions.some(
      (entity: Suggestion) => compareString && entity.value.name === compareString,
    );
    showIsExisting(exists);
    return exists;
  }

  function showIsExisting(value: boolean) {
    if (!allowCreateNew) {
      return;
    }
    setIsExisting(value);
  }

  function setValidName(value: string) {
    value = value.substring(0, 255);
    setSuggestionName(value);
  }

  function findSuggestionImage(name?: string) {
    const suggestion = suggestionArray.find((s) => s.value.name === name);
    if (suggestion?.value.hasOwnProperty('image')) {
      return (suggestion.value as { image?: string }).image ?? '';
    }
    return '';
  }

  function onSelection(suggestion?: Suggestion) {
    const name = suggestion?.value.name;
    if (!name) {
      return;
    }
    tryToUpdateIcon(suggestion);

    setSuggestionName(name);
    setLogo(findSuggestionImage(name));
    onChange(name);
    checkExists(name);
    setShowList(false);
  }

  function onFocusTextInput() {
    onInputFocus();
    setIsFocused(true);
  }

  function onBlurTextInput() {
    setIsFocused(false);
    onInputBlur();
  }

  function tryToUpdateIcon(suggestion?: Suggestion) {
    const suggestionToExtract =
      suggestion ?? suggestions.find((v) => v.value.name === suggestionName);

    if (suggestionToExtract?.value.hasOwnProperty('icon')) {
      setIcon((suggestionToExtract.value as { icon?: string }).icon);
    } else {
      setIcon(undefined);
    }
  }

  return (
    <Layout.Column
      onFocus={() => setShowList(true)}
      onBlur={(event: FixMe) => {
        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }
        setShowList(false);
      }}
      ref={divRef}
    >
      {flipDropdown ? (
        <Layout.Column>
          <Layout.Column
            border={!showList ? [0] : [1, 'solid', 'grayLight']}
            style={{
              borderTopWidth: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: zIndex.Dropdown,
              flexDirection: 'column-reverse',
            }}
            bg="inputBackground"
          >
            {showList
              ? suggestions.slice(0, MAX_DISPLAYED_SUGGESTIONS).map((suggestion: Suggestion) => {
                  if (suggestion.value.name.toLowerCase().includes(suggestionName.toLowerCase())) {
                    return (
                      <SuggestionRow
                        key={suggestion.key}
                        item={suggestion}
                        onSelection={onSelection}
                        search={suggestionName}
                      />
                    );
                  }
                  return null;
                })
              : null}
          </Layout.Column>
        </Layout.Column>
      ) : null}

      <LabelTextField
        label={headerText}
        error={error}
        styles={{ backgroundColor: theme.colors.inputBackground, ...borderStyles }}
        active={isFocused}
      >
        <Layout.Row align style={{ width: '100%' }}>
          <InputIcon icon={icon} logo={logo} iconName={iconName} />

          <Spacer.Horizontal size={8} />
          <OpenSans.Input
            ref={inputRef}
            name="client"
            value={suggestionName}
            type="text"
            placeholder={placeHolderText}
            grow
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValidName(event.target.value)
            }
            autoComplete="off"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                if (suggestions[0]?.value?.name) {
                  onSelection(suggestions[0]);
                }
              }
            }}
            onFocus={() => onFocusTextInput()}
            onBlur={() => onBlurTextInput()}
          />
          {!isExisting && suggestionName.length !== 0 ? (
            <Layout.Row radius={100} px={8} py={2} bg="brandPrimary">
              <OpenSans.Inverse size="xs-12">New</OpenSans.Inverse>
            </Layout.Row>
          ) : null}
        </Layout.Row>
      </LabelTextField>

      {!flipDropdown ? (
        <Layout.Column>
          <Layout.Column
            border={!showList ? [0] : [1, 'solid', 'grayLight']}
            style={{
              borderTopWidth: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
              position: 'absolute',
              zIndex: zIndex.Dropdown,
              left: 0,
              right: 0,
            }}
            bg="inputBackground"
          >
            {showList
              ? suggestions.slice(0, MAX_DISPLAYED_SUGGESTIONS).map((suggestion) => {
                  if (suggestion.value.name.toLowerCase().includes(suggestionName.toLowerCase())) {
                    return (
                      <SuggestionRow
                        key={suggestion.key}
                        item={suggestion}
                        onSelection={onSelection}
                        search={suggestionName}
                      />
                    );
                  }
                  return null;
                })
              : null}
          </Layout.Column>
        </Layout.Column>
      ) : null}
    </Layout.Column>
  );
};

const SuggestionRow: React.FC<{
  item: Suggestion;
  onSelection: (suggestion?: Suggestion) => void;
  search?: string;
}> = ({ item, onSelection, search }) => {
  const value: {
    name: string;
    image?: string | null | undefined;
    icon?: string | null | undefined;
  } = item.value;

  const index = value.name?.toLowerCase().indexOf(search?.toLowerCase() ?? '');

  const start = index !== undefined && search ? value.name?.substring(0, index) : value.name;
  const middle =
    index !== undefined && search ? value.name?.substring(index, index + search.length) : '';
  const end = index !== undefined && search ? value.name?.substring(index + search.length) : '';

  return (
    <Layout.PressableRow
      style={{
        overflow: 'hidden',
        width: '100%',
      }}
      onClick={() => onSelection(item)}
      align
      px
      py={8}
      bg="inputBackground"
      radius
      onMouseDown={(event: FixMe) => event.preventDefault()}
    >
      {value.image ? (
        <img
          style={{
            borderRadius: 50,
            height: 20,
            width: 20,
            verticalAlign: 'middle',
          }}
          src={value.image}
        />
      ) : (
        <Circle.Solid bg="brandPrimaryLight" circleSize={20} center>
          <OpenSans.Primary size={12}>
            {value.icon ? (value.icon ? value.icon : value.name[0]) : ''}
          </OpenSans.Primary>
        </Circle.Solid>
      )}

      <Spacer.Horizontal size={8} />

      <OpenSans.Primary size="s-16" grow color="grayDark" numberOfLines={1}>
        {start}
        <OpenSans.Primary
          size="s-16"
          weight="bold-700"
          color="primary"
          style={{ textDecorationLine: 'underline' }}
        >
          {middle}
        </OpenSans.Primary>
        {end}
      </OpenSans.Primary>
    </Layout.PressableRow>
  );
};

const InputIcon: React.FC<{ icon?: string; logo?: string; iconName: IconName }> = ({
  icon,
  logo,
  iconName,
}) => {
  if (logo) {
    return <img style={{ borderRadius: 50, height: 20, width: 20 }} src={logo} />;
  }
  if (icon) {
    return (
      <OpenSans.Primary size={20} weight="bold-700">
        {icon}
      </OpenSans.Primary>
    );
  }
  return <Icon name={iconName} size={20} />;
};
