import { Layout, Spacer } from '@easy-expense/ui-web-core';
import { EntityIDTypes, Wrapped } from '@easy-expense/utils-shared';
import cuid from 'cuid';
import React from 'react';

import { useEphemeralKeyNonce } from '../../hooks/issuing/useEphemeralKeyNonce';

export const CardDetailsRow: React.FC<{
  card: Wrapped.Card;
  accountID: EntityIDTypes.ConnectedAccountID;
}> = ({ card, accountID }) => {
  const divIDPrefix = React.useMemo(() => `card-detail-row-${cuid()}-${card.id}`, []);
  useEphemeralKeyNonce(card.id as EntityIDTypes.CardID, divIDPrefix, accountID);
  return (
    <Layout.Column grow>
      <Layout.Row>
        <Layout.Column grow radius px py bg={'brandPrimaryLight'}>
          <div id={divIDPrefix + 'issuingCardNumberDisplay'} />
        </Layout.Column>
      </Layout.Row>
      <Spacer.Vertical />
      <Layout.Row>
        <Layout.Column grow radius px py bg={'brandPrimaryLight'}>
          <div id={divIDPrefix + 'issuingCardCvcDisplay'} />
        </Layout.Column>
        <Spacer.Horizontal />
        <Layout.Column grow radius px py bg={'brandPrimaryLight'}>
          <div id={divIDPrefix + 'issuingCardExpiryDisplay'} />
        </Layout.Column>
        <Spacer.Horizontal />
        <Layout.Column grow radius px py bg={'brandPrimaryLight'}>
          <div id={divIDPrefix + 'issuingCardPinDisplay'} />
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};
