import { StripeClientWrappers } from '@easy-expense/auth-client';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { EntityIDTypes, Wrapped } from '@easy-expense/utils-shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const CardIssueButton: React.FC<{
  cardholder: Wrapped.Cardholder;
  accountID: EntityIDTypes.ConnectedAccountID;
}> = ({ cardholder, accountID }) => {
  const navigate = useNavigate();
  const [working, setWorking] = React.useState<boolean>(false);
  const createCard = React.useCallback(() => {
    setWorking(true);
    StripeClientWrappers.Cards.createCard(accountID, cardholder)
      .then(() => {
        navigate('/issuing/cards', { replace: false });
      })
      .catch((e) => {
        console.error(e);
        alert('There was an error');
      })
      .finally(() => {
        setWorking(false);
      });
  }, [navigate]);
  return (
    <Layout.PressableColumn py={2} px radius disabled={working} onClick={createCard}>
      <OpenSans.Pressable>{working ? 'Working' : 'Issue a Card'}</OpenSans.Pressable>
    </Layout.PressableColumn>
  );
};
