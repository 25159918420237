import { StripeClientWrappers } from '@easy-expense/auth-client';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import {
  EEtoStripeParse,
  EntityIDTypes,
  StripeToString,
  Wrapped,
} from '@easy-expense/utils-shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const FundButton: React.FC<{
  source: Wrapped.Source;
  accountID: EntityIDTypes.ConnectedAccountID;
}> = ({ source, accountID }) => {
  const navigate = useNavigate();
  const [working, setWorking] = React.useState<boolean>(false);
  const createTopup = React.useCallback(() => {
    setWorking(true);
    //@wmatous different currencies
    const amount = EEtoStripeParse.rawToAmount(
      parseInt(prompt('How many dollars?') ?? 'NaN', 10),
      'usd',
    );
    if (isNaN(amount)) {
      setWorking(false);
      alert('Please enter an amount');
    } else {
      const confirmString: 'confirm' = 'confirm';
      const confirm = prompt(
        `Type '${confirmString}' to confirm ${StripeToString.Balance.amountToString(amount)}`,
      );
      if (confirm === confirmString) {
        StripeClientWrappers.Topups.createTopup(accountID, source, amount)
          .then(() => {
            navigate('/issuing/balance', { replace: false });
          })
          .catch((e) => {
            alert(e);
          })
          .finally(() => {
            setWorking(false);
          });
      } else {
        alert('Please try again');
      }
    }
  }, [navigate]);
  return (
    <Layout.PressableColumn py={2} px radius disabled={working} onClick={createTopup}>
      <OpenSans.Pressable>{working ? 'Working' : 'Draw Funds'}</OpenSans.Pressable>
    </Layout.PressableColumn>
  );
};
